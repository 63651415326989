import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import rotations from "../../entities/rotations";

type RotationListProps = {
  label: string;
  rotation: number;
  setRotation: (index: number) => void;
};

function RotationList({ label, rotation, setRotation }: RotationListProps) {
  const handleChange = (event: SelectChangeEvent) => {
    let index = parseInt(event.target.value);
    if (isNaN(index)) {
      index = 0;
    }
    setRotation(index);
  };

  const listItems = rotations.map((d, i) => (
    <MenuItem key={i} value={i}>
      {d.label}
    </MenuItem>
  ));

  return (
    <FormControl fullWidth>
      <InputLabel id={label + "-select-label"}>{label}</InputLabel>
      <Select
        labelId={label + "-select-label"}
        id={label + "-select"}
        value={rotation.toString()}
        label={label}
        onChange={handleChange}
      >
        {listItems}
      </Select>
    </FormControl>
  );
}

export default RotationList;
