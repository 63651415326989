import { useAppSelector } from "app/hooks";
import rotations from "entities/rotations";
import React, { useEffect, useRef } from "react";
import { selectCurrentStream } from "stateSlices/settingsSlice";

function VideoPlayer() {
  const currentStream = useAppSelector(selectCurrentStream);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current !== null) {
      const stream = videoRef.current.srcObject;
      if (stream instanceof MediaStream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      videoRef.current.srcObject = null;
    }

    if (
      currentStream?.videoInputId === undefined ||
      currentStream?.audioInputId === undefined
    ) {
      return;
    }

    navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId: {
            exact: currentStream.videoInputId,
          },
          width: currentStream.width,
          height: currentStream.height,
          frameRate: currentStream.refreshRate,
        },
        audio: {
          deviceId: {
            exact: currentStream.audioInputId,
          },
          channelCount: 2,
          autoGainControl: false,
          noiseSuppression: false,
          echoCancellation: false,
        },
      })
      .then((stream) => {
        const video = videoRef.current;
        if (video !== null) {
          video.srcObject = stream;
          video.onloadedmetadata = () => {
            video.play();
          };
        }
      })
      .catch((err) => {
        console.error("error:", err);
      });
  }, [videoRef, currentStream]);

  if (
    currentStream?.videoInputId !== undefined &&
    currentStream?.audioInputId !== undefined
  ) {
    return (
      <video
        ref={videoRef}
        className={rotations[currentStream.rotation].cssClass}
      />
    );
  } else {
    return (
      <h1 className="center-text">This site requires access to the webcam!</h1>
    );
  }
}

export default VideoPlayer;
