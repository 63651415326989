import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

type InputListProps = {
  label: string;
  inputs: MediaDeviceInfo[] | undefined;
  currentValue: string | undefined;
  setValue: (inputId: string | undefined) => void;
};

function InputList({ label, inputs, currentValue, setValue }: InputListProps) {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value !== "" ? event.target.value : undefined);
  };

  const listItems =
    inputs !== undefined
      ? inputs.map((d, i) => (
          <MenuItem key={i} value={d.deviceId}>
            {d.label}
          </MenuItem>
        ))
      : [];

  listItems.unshift(
    <MenuItem key={-1} value="">
      -- No source --
    </MenuItem>
  );

  return (
    <FormControl fullWidth>
      <InputLabel id={label + "-select-label"}>{label}</InputLabel>
      <Select
        labelId={label + "-select-label"}
        id={label + "-select"}
        value={currentValue ?? ""}
        label={label}
        onChange={handleChange}
      >
        {listItems}
      </Select>
    </FormControl>
  );
}

export default InputList;
