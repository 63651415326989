class VideoPlayerSettings {
  index: number;
  hasUnsavedChanges: boolean;
  name: string;
  stream: {
    videoInputId: string | undefined;
    audioInputId: string | undefined;
    width: number;
    height: number;
    refreshRate: number;
    rotation: number;
  };

  constructor(index: number) {
    this.index = index;
    this.hasUnsavedChanges = true;
    this.name = "NEW DEVICE";
    this.stream = {
      videoInputId: undefined,
      audioInputId: undefined,
      width: 1920,
      height: 1080,
      refreshRate: 30,
      rotation: 0,
    };
  }
}

export default VideoPlayerSettings;
