import React from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  deleteDevice,
  moveDeviceDown,
  moveDeviceUp,
  selectCurrentId,
  switchDevice,
} from "stateSlices/settingsSlice";
import {
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";

type DeviceButtonProps = {
  text: string;
  index: number;
  unsaved: boolean;
};

function DeviceButton({ text, index, unsaved }: DeviceButtonProps) {
  const currentId = useAppSelector(selectCurrentId);
  const dispatch = useAppDispatch();

  let buttonText = text;
  if (unsaved) {
    buttonText += " *";
  }

  return (
    <ListItem>
      <ListItemButton
        selected={currentId === index}
        onClick={() => dispatch(switchDevice(index))}
      >
        <ListItemText primary={buttonText} />
      </ListItemButton>
      <IconButton
        aria-label="move up"
        onClick={() => dispatch(moveDeviceUp(index))}
      >
        <KeyboardArrowUpIcon />
      </IconButton>
      <IconButton
        aria-label="move down"
        onClick={() => dispatch(moveDeviceDown(index))}
      >
        <KeyboardArrowDownIcon />
      </IconButton>
      <Divider orientation="vertical" flexItem />
      <IconButton
        aria-label="delete"
        onClick={() => dispatch(deleteDevice(index))}
      >
        <DeleteIcon />
      </IconButton>
    </ListItem>
  );
}

export default DeviceButton;
