const resolutions = [
  {
    width: 640,
    height: 360,
    ratio: "16:9",
  },
  {
    width: 640,
    height: 480,
    ratio: "4:3",
  },
  {
    width: 960,
    height: 540,
    ratio: "16:9",
  },
  {
    width: 1024,
    height: 768,
    ratio: "4:3",
  },
  {
    width: 1280,
    height: 1024,
    ratio: "5:4",
  },
  {
    width: 1280,
    height: 720,
    ratio: "16:9",
  },
  {
    width: 1280,
    height: 800,
    ratio: "16:10",
  },
  {
    width: 1440,
    height: 900,
    ratio: "16:10",
  },
  {
    width: 1600,
    height: 1200,
    ratio: "4:3",
  },
  {
    width: 1920,
    height: 1080,
    ratio: "16:9",
  },
  {
    width: 1920,
    height: 1200,
    ratio: "16:10",
  },
  {
    width: 2048,
    height: 1024,
    ratio: "5:4",
  },
  {
    width: 2048,
    height: 1080,
    ratio: "1.90:1",
  },
  {
    width: 2560,
    height: 1440,
    ratio: "16:9",
  },
  {
    width: 2560,
    height: 1600,
    ratio: "16:10",
  },
  {
    width: 3200,
    height: 1800,
    ratio: "16:9",
  },
  {
    width: 3840,
    height: 2160,
    ratio: "16:9",
  },
  {
    width: 4096,
    height: 2160,
    ratio: "1.90:1",
  },
];

export default resolutions;
