import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

type RefreshRateListProps = {
  label: string;
  refreshRate: number;
  setRefreshRate: (value: number) => void;
};

function RefreshRateList({
  label,
  refreshRate,
  setRefreshRate,
}: RefreshRateListProps) {
  const refreshRates: number[] = [30, 60];

  const handleChange = (event: SelectChangeEvent) => {
    let value = parseInt(event.target.value);
    if (isNaN(value)) {
      value = refreshRates[0];
    }
    setRefreshRate(value);
  };

  const listItems = refreshRates.map((d, i) => (
    <MenuItem key={i} value={d}>
      {d}
    </MenuItem>
  ));

  return (
    <FormControl fullWidth>
      <InputLabel id={label + "-select-label"}>{label}</InputLabel>
      <Select
        labelId={label + "-select-label"}
        id={label + "-select"}
        value={refreshRate.toString()}
        label={label}
        onChange={handleChange}
      >
        {listItems}
      </Select>
    </FormControl>
  );
}

export default RefreshRateList;
