import React, { useState } from "react";
import { Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import "./App.css";
import SettingsModal from "./components/settingsModal/SettingsModal";
import VideoPlayer from "./components/VideoPlayer";

function App() {
  const [showSettings, setShowSettings] = useState(false);

  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#ff9800",
      },
      secondary: {
        main: "#0067ff",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="outlined"
        className="settings-button"
        onClick={() => setShowSettings(true)}
      >
        <SettingsIcon fontSize="large" />
      </Button>
      <SettingsModal visible={showSettings} show={setShowSettings} />
      <VideoPlayer />
    </ThemeProvider>
  );
}

export default App;
