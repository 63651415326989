import React, { useEffect, useState } from "react";
import InputList from "./InputList";
import ResolutionList from "./ResolutionList";
import RefreshRateList from "./RefreshRateList";
import RotationList from "./RotationList";
import DeviceButton from "./DeviceButton";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  addNewDevice,
  saveDevices,
  selectCurrentDevice,
  selectSettings,
  setAudioInput,
  setHeight,
  setName,
  setRefreshRate,
  setRotation,
  setVideoInput,
  setWidth,
} from "stateSlices/settingsSlice";
import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Stack,
  TextField,
} from "@mui/material";

type SettingsModalProps = {
  visible: boolean;
  show: React.Dispatch<React.SetStateAction<boolean>>;
};

function SettingsModal({ visible, show }: SettingsModalProps) {
  const currentDevice = useAppSelector(selectCurrentDevice);
  const settings = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();
  const [noDevices, setNoDevices] = useState(false);
  const [videoInputs, setVideoInputs] = useState<MediaDeviceInfo[] | undefined>(
    undefined
  );
  const [audioInputs, setAudioInputs] = useState<MediaDeviceInfo[] | undefined>(
    undefined
  );

  const loadInputs = () => {
    navigator.mediaDevices.enumerateDevices().then((all) => {
      setVideoInputs(all.filter((d) => d.kind === "videoinput"));
      setAudioInputs(all.filter((d) => d.kind === "audioinput"));

      if (all.some((d) => d.kind === "videoinput" || d.kind === "audioinput")) {
        setNoDevices(false);
      } else {
        setNoDevices(true);
      }
    });
  };

  const triggerInputRequest = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        stream.getTracks().forEach((track) => track.stop());
        loadInputs();
      });
  };

  useEffect(() => {
    loadInputs();
  }, []);

  const closeSettings = () => {
    show(false);
  };

  if (!visible) {
    return null;
  }

  const deviceButtons = settings.devices.map((d, i) => (
    <DeviceButton
      key={i}
      text={d.name}
      index={d.index}
      unsaved={d.hasUnsavedChanges}
    />
  ));

  return (
    <Dialog
      open={visible}
      onClose={closeSettings}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={currentDevice.name}
            onChange={(event) => dispatch(setName(event.target.value))}
          />
          {noDevices && (
            <div>
              <Alert severity="warning">
                Could not load media devices!
                <Button onClick={triggerInputRequest}>Retry</Button>
              </Alert>
            </div>
          )}
          <InputList
            label="Video input"
            inputs={videoInputs}
            currentValue={currentDevice.stream.videoInputId}
            setValue={(value) => dispatch(setVideoInput(value))}
          />
          <InputList
            label="Audio input"
            inputs={audioInputs}
            currentValue={currentDevice.stream.audioInputId}
            setValue={(value) => dispatch(setAudioInput(value))}
          />
          <ResolutionList
            label="Resolution"
            width={currentDevice.stream.width}
            setWidth={(value) => dispatch(setWidth(value))}
            height={currentDevice.stream.height}
            setHeight={(value) => dispatch(setHeight(value))}
          />
          <RefreshRateList
            label="Refresh rate"
            refreshRate={currentDevice.stream.refreshRate}
            setRefreshRate={(value) => dispatch(setRefreshRate(value))}
          />
          <RotationList
            label="Rotation"
            rotation={currentDevice.stream.rotation}
            setRotation={(value) => dispatch(setRotation(value))}
          />
          <List component="nav" aria-label="main mailbox folders">
            {deviceButtons}
          </List>
          <div>
            <Button onClick={() => dispatch(addNewDevice())}>New Device</Button>
            <Badge
              badgeContent={+settings.hasUnsavedChanges}
              variant="dot"
              color="primary"
            >
              <Button onClick={() => dispatch(saveDevices())}>Save</Button>
            </Badge>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeSettings}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SettingsModal;
