import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import VideoPlayerSettings from "entities/videoSettings";

const storageName = "savedDevices";

interface SettingsState {
  currentDeviceIndex: number;
  hasUnsavedChanges: boolean;
  hasDeletedDevices: boolean;
  devices: VideoPlayerSettings[];
  savedDevices: VideoPlayerSettings[];
}

const sortDevices = (devices: VideoPlayerSettings[]): VideoPlayerSettings[] => {
  return devices.slice().sort((a, b) => {
    return a.index - b.index;
  });
};

const checkDeviceForChanges = (
  state: SettingsState,
  index: number
): boolean => {
  const a = state.savedDevices[index];
  const b = state.devices[index];

  if (a === undefined) {
    return true;
  }

  return (
    a.index !== b.index ||
    a.name !== b.name ||
    JSON.stringify(a.stream) !== JSON.stringify(b.stream)
  );
};

const checkForAnyChanges = (state: SettingsState): boolean => {
  if (
    state.hasDeletedDevices &&
    state.devices.length === state.savedDevices.length
  ) {
    return state.devices.some((_, i) => checkDeviceForChanges(state, i));
  }

  return (
    state.devices.some((d) => d.hasUnsavedChanges) || state.hasDeletedDevices
  );
};

const generateInitialState = (): SettingsState => {
  const loadedDevices = localStorage.getItem(storageName);

  let parsedDevices: VideoPlayerSettings[];
  let savedDevices: VideoPlayerSettings[] = [];
  if (loadedDevices) {
    parsedDevices = JSON.parse(loadedDevices);
    savedDevices = parsedDevices;
  } else {
    const newDevice = new VideoPlayerSettings(0);
    parsedDevices = [{ ...newDevice }];
  }

  document.title = parsedDevices[0].name;

  return {
    currentDeviceIndex: 0,
    hasUnsavedChanges: savedDevices.length === 0,
    hasDeletedDevices: false,
    devices: parsedDevices,
    savedDevices: savedDevices,
  };
};

const initialState: SettingsState = generateInitialState();

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.devices[state.currentDeviceIndex].name = action.payload;
      state.devices[state.currentDeviceIndex].hasUnsavedChanges =
        checkDeviceForChanges(state, state.currentDeviceIndex);
      state.hasUnsavedChanges = checkForAnyChanges(state);
      document.title = state.devices[state.currentDeviceIndex].name;
    },
    setVideoInput: (state, action: PayloadAction<string | undefined>) => {
      state.devices[state.currentDeviceIndex].stream.videoInputId =
        action.payload;
      state.devices[state.currentDeviceIndex].hasUnsavedChanges =
        checkDeviceForChanges(state, state.currentDeviceIndex);
      state.hasUnsavedChanges = checkForAnyChanges(state);
    },
    setAudioInput: (state, action: PayloadAction<string | undefined>) => {
      state.devices[state.currentDeviceIndex].stream.audioInputId =
        action.payload;
      state.devices[state.currentDeviceIndex].hasUnsavedChanges =
        checkDeviceForChanges(state, state.currentDeviceIndex);
      state.hasUnsavedChanges = checkForAnyChanges(state);
    },
    setWidth: (state, action: PayloadAction<number>) => {
      state.devices[state.currentDeviceIndex].stream.width = action.payload;
      state.devices[state.currentDeviceIndex].hasUnsavedChanges =
        checkDeviceForChanges(state, state.currentDeviceIndex);
      state.hasUnsavedChanges = checkForAnyChanges(state);
    },
    setHeight: (state, action: PayloadAction<number>) => {
      state.devices[state.currentDeviceIndex].stream.height = action.payload;
      state.devices[state.currentDeviceIndex].hasUnsavedChanges =
        checkDeviceForChanges(state, state.currentDeviceIndex);
      state.hasUnsavedChanges = checkForAnyChanges(state);
    },
    setRefreshRate: (state, action: PayloadAction<number>) => {
      state.devices[state.currentDeviceIndex].stream.refreshRate =
        action.payload;
      state.devices[state.currentDeviceIndex].hasUnsavedChanges =
        checkDeviceForChanges(state, state.currentDeviceIndex);
      state.hasUnsavedChanges = checkForAnyChanges(state);
    },
    setRotation: (state, action: PayloadAction<number>) => {
      state.devices[state.currentDeviceIndex].stream.rotation = action.payload;
      state.devices[state.currentDeviceIndex].hasUnsavedChanges =
        checkDeviceForChanges(state, state.currentDeviceIndex);
      state.hasUnsavedChanges = checkForAnyChanges(state);
    },
    switchDevice: (state, action: PayloadAction<number>) => {
      state.currentDeviceIndex = action.payload;
      document.title = state.devices[action.payload].name;
    },
    moveDeviceUp: (state, action: PayloadAction<number>) => {
      if (action.payload < 1) {
        return state;
      }

      state.devices[action.payload - 1].index = action.payload;
      state.devices[action.payload - 1].hasUnsavedChanges =
        checkDeviceForChanges(state, action.payload - 1);
      state.devices[action.payload].index = action.payload - 1;
      state.devices[action.payload].hasUnsavedChanges = checkDeviceForChanges(
        state,
        action.payload
      );
      state.hasUnsavedChanges = checkForAnyChanges(state);
      state.devices = sortDevices(state.devices);

      state.currentDeviceIndex =
        action.payload === state.currentDeviceIndex
          ? state.currentDeviceIndex - 1
          : action.payload - 1 === state.currentDeviceIndex
          ? state.currentDeviceIndex + 1
          : state.currentDeviceIndex;
    },
    moveDeviceDown: (state, action: PayloadAction<number>) => {
      if (action.payload >= state.devices.length - 1) {
        return state;
      }

      state.devices[action.payload].index = action.payload + 1;
      state.devices[action.payload].hasUnsavedChanges = checkDeviceForChanges(
        state,
        action.payload
      );
      state.devices[action.payload + 1].index = action.payload;
      state.devices[action.payload + 1].hasUnsavedChanges =
        checkDeviceForChanges(state, action.payload + 1);
      state.hasUnsavedChanges = checkForAnyChanges(state);
      state.devices = sortDevices(state.devices);

      state.currentDeviceIndex =
        action.payload === state.currentDeviceIndex
          ? state.currentDeviceIndex + 1
          : action.payload + 1 === state.currentDeviceIndex
          ? state.currentDeviceIndex - 1
          : state.currentDeviceIndex;
    },
    addNewDevice: (state) => {
      const newDevice = new VideoPlayerSettings(state.devices.length);
      state.devices.push({ ...newDevice });
      state.hasUnsavedChanges = checkForAnyChanges(state);
    },
    saveDevices: (state) => {
      state.devices = state.devices.map((item) => {
        item.hasUnsavedChanges = false;
        return item;
      });
      state.hasUnsavedChanges = false;
      state.hasDeletedDevices = false;

      localStorage.setItem(storageName, JSON.stringify(state.devices));
      state.savedDevices = state.devices;
      return state;
    },
    deleteDevice: (state, action: PayloadAction<number>) => {
      state.devices.splice(action.payload, 1);
      state.devices = state.devices.map((d, i) => {
        d.index = i;
        return d;
      });
      state.hasDeletedDevices = true;
      if (state.devices.length === 0) {
        const newDevice = new VideoPlayerSettings(0);
        state.devices = [{ ...newDevice }];
      }
      state.hasUnsavedChanges = checkForAnyChanges(state);

      if (state.currentDeviceIndex >= state.devices.length) {
        state.currentDeviceIndex = state.devices.length - 1;
        document.title = state.devices[state.devices.length - 1].name;
      }
    },
  },
});

export const {
  setName,
  setVideoInput,
  setAudioInput,
  setWidth,
  setHeight,
  setRefreshRate,
  setRotation,
  switchDevice,
  moveDeviceUp,
  moveDeviceDown,
  addNewDevice,
  saveDevices,
  deleteDevice,
} = settingsSlice.actions;

export const selectSettings = (state: RootState) => state.settings;
export const selectCurrentId = (state: RootState) =>
  state.settings.currentDeviceIndex;
export const selectCurrentDevice = (state: RootState) =>
  state.settings.devices[state.settings.currentDeviceIndex];
export const selectCurrentStream = (state: RootState) =>
  state.settings.devices[state.settings.currentDeviceIndex].stream;
export const selectDevices = (state: RootState) => state.settings.devices;

export default settingsSlice.reducer;
