import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import resolutions from "../../entities/resolutions";

type ResolutionListProps = {
  label: string;
  width: number;
  setWidth: (value: number) => void;
  height: number;
  setHeight: (value: number) => void;
};

function ResolutionList({
  label,
  width,
  setWidth,
  height,
  setHeight,
}: ResolutionListProps) {
  const [currentValue, setCurrentValue] = useState<string>("0");

  useEffect(() => {
    let value = resolutions.findIndex(
      (r) => r.width === width && r.height === height
    );
    if (value === -1) {
      value = 0;
    }
    setCurrentValue(value.toString());
  }, [width, height]);

  const handleChange = (event: SelectChangeEvent) => {
    let index = parseInt(event.target.value);
    if (isNaN(index)) {
      index = 0;
    }
    setWidth(resolutions[index].width);
    setHeight(resolutions[index].height);
  };

  const listItems = resolutions.map((d, i) => (
    <MenuItem key={i} value={i}>
      {d.width}x{d.height} ({d.ratio})
    </MenuItem>
  ));

  return (
    <FormControl fullWidth>
      <InputLabel id={label + "-select-label"}>{label}</InputLabel>
      <Select
        labelId={label + "-select-label"}
        id={label + "-select"}
        value={currentValue}
        label={label}
        onChange={handleChange}
      >
        {listItems}
      </Select>
    </FormControl>
  );
}

export default ResolutionList;
